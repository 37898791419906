var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    staticClass: "non-overflow",
    style: {
      zIndex: _vm.options.zIndex
    },
    attrs: {
      "max-width": _vm.options.width
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        !_vm.rejection && _vm.cancel;
      }
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "px-12 py-5"
  }, [_c('v-container', {
    staticClass: "d-flex"
  }, [_c('v-card-text', {
    staticClass: "text-h2"
  }, [_vm._v(" My Profile ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.agree.apply(null, arguments);
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.mdiClose))])], 1)], 1), _c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-md-body-1"
  }, [_vm._v(" Recruiter Name: ")])]), _c('v-col', {
    staticClass: "average-font pl-1",
    attrs: {
      "cols": "8"
    }
  }, [!_vm.editMode ? _c('div', [_vm._v(" " + _vm._s(_vm.name) + " ")]) : _c('div', [_vm.editMode ? _c('v-text-field', {
    attrs: {
      "counter": "",
      "rules": _vm.nameRules
    },
    model: {
      value: _vm.tempName,
      callback: function ($$v) {
        _vm.tempName = $$v;
      },
      expression: "tempName"
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-md-body-1"
  }, [_vm._v(" Company Name:")])]), _c('v-col', {
    staticClass: "average-font pl-1",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.company) + " ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-md-body-1"
  }, [_vm._v(" Joining Date:")])]), _c('v-col', {
    staticClass: "average-font pl-1",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.date) + " ")])], 1)], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-md-body-1"
  }, [_vm._v(" Role:")])]), _c('v-col', {
    staticClass: "average-font pl-1",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.role) + " ")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-avatar', {
    staticStyle: {
      "margin-left": "auto",
      "margin-right": "auto"
    },
    attrs: {
      "size": 200
    }
  }, [_vm.imageEdit ? _c('Loader', {
    staticClass: "mr-14"
  }) : _vm._e(), !_vm.imageEdit ? _c('img', {
    attrs: {
      "src": _vm.profileImageUrl ? _vm.profileImageUrl : require('./../../assets/images/icon/no_dp.png')
    }
  }) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "justify-center"
  }, [_vm.editMode ? _c('input', {
    ref: "avatarFile",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".png, .jpg, .jpeg"
    },
    on: {
      "change": _vm.uploadAttachmentSave
    }
  }) : _vm._e(), _vm.editMode ? _c('v-btn', {
    staticClass: "font-weight-bold justify-center red--text",
    attrs: {
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.openImage
    }
  }, [_vm._v(" Change Profile Photo ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-container', [_c('v-card-text', {}, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-md-body-1"
  }, [_vm._v(" Description: ")])]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [!_vm.editMode ? _c('div', [_c('span', {
    staticClass: "average-font"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])]) : _vm._e(), _vm.editMode ? _c('div', [_vm.editMode ? _c('v-textarea', {
    attrs: {
      "counter": "",
      "outlined": _vm.editMode,
      "rules": _vm.descRules
    },
    model: {
      value: _vm.tempDesc,
      callback: function ($$v) {
        _vm.tempDesc = $$v;
      },
      expression: "tempDesc"
    }
  }) : _vm._e()], 1) : _vm._e()])], 1)], 1)], 1), _c('v-card-actions', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editMode,
      expression: "!editMode"
    }],
    staticClass: "py-4 px-8 justify-end"
  }, [_vm.rejection ? _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "indigo"
    },
    on: {
      "click": _vm.onEdit
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1), _c('v-card-actions', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editMode,
      expression: "editMode"
    }],
    staticClass: "py-4 px-8 justify-center"
  }, [_vm.rejection ? _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "outlined": ""
    },
    on: {
      "click": _vm.onDiscard
    }
  }, [_vm._v(" Back (Discard change) ")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none text-subtitle-1 font-weight-medium px-2",
    attrs: {
      "large": "",
      "color": _vm.options.color,
      "outlined": ""
    },
    on: {
      "click": _vm.onSave
    }
  }, [_c('span', [_vm._v(" Save ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }